<template>
  <div class="jxyh-monitor">
    <div class="jxyh-monitor-tool">
      <img v-for="size in sizes" :key="size.id" :src="size.id == currentSize ? size.iconHl : size.icon"
        class="jxyh-monitor-tool-size" @click="changeSize(size.id)" />
      <monitor-select :options="cameraList" @save="saveCameraList"></monitor-select>
    </div>
    <div class="jxyh-monitor-sizes">
      <template v-if="monitorRows.length">
        <el-row v-for="(row, index) in monitorRows" :key="`${currentSize}_row_${index}`" :gutter="16">
          <el-col v-for="(cell, cellIndex) in row" :key="`${currentSize}_cell_${index}_${cellIndex}`"
            :span="24 / row.length">
            <div v-if="cell.channelNo" class="cell-monitor">
              <zy-monitor v-if="cell.channelNo" :channelNo="cell.channelNo" :cameraIndexCode="cell.cameraIndexCode"
                @click="towerCraneClick(cell)"></zy-monitor>
            </div>
            <zy-img-gif path="monitoring/empty" :f-style="gifStyle.monitor" v-else></zy-img-gif>
          </el-col>
        </el-row>
      </template>
      <div v-else class="jxyh-monitor-sizes-empty">
        <zy-img-gif path="monitoring/empty" :f-style="gifStyle.default"></zy-img-gif>
      </div>
    </div>
    <zy-dialog :title="points.label" :visible.sync="visible">
      <zy-monitor v-if="points && points.cameraIndexCode" :channelNo="points.channelNo"
        :cameraIndexCode="points.cameraIndexCode" template-id="d255475d2b9e4cb2908853fc62d9584b"></zy-monitor>
    </zy-dialog>
  </div>
</template>

<script>
import monitorSelect from "./components/select"
import { getProjectCamera } from "@/jxyh/api/monitor"
import components from "@/jxyh/components"
import MonitorLocal from "./local"
export default {
  ...components(["ZyImgGif", "ZyMonitor", "ZyDialog"], { monitorSelect }),
  data() {
    return {
      currentSize: 2,
      sizes: [
        {
          icon: require("@/jxyh/assets/icons/icon_monitor_size_4.webp"),
          iconHl: require("@/jxyh/assets/icons/icon_monitor_size_4_hl.webp"),
          id: 2,
        },
        {
          icon: require("@/jxyh/assets/icons/icon_monitor_size_8.webp"),
          iconHl: require("@/jxyh/assets/icons/icon_monitor_size_8_hl.webp"),
          id: 3,
        },
        {
          icon: require("@/jxyh/assets/icons/icon_monitor_size_16.webp"),
          iconHl: require("@/jxyh/assets/icons/icon_monitor_size_16_hl.webp"),
          id: 4,
        },
      ],
      cameraList: {},
      selectCameraList: [],
      points: {},
      visible: false,
      gifStyle: {
        default: { width: "100%", height: "auto", aspectRatio: "16/9" },
        monitor: { width: "100%", height: "auto", aspectRatio: "16/7.5" },
      },
      local: null
    }
  },
  computed: {
    monitorRows() {
      const rows = []
      if (!this.selectCameraList.length) return rows
      let readIndex = 0
      //行数
      for (let i = 0; i < this.currentSize; i++) {
        const row = []
        for (let cellIndex = 0; cellIndex < this.currentSize; cellIndex++) {
          if (this.selectCameraList[readIndex]) {
            row.push(this.selectCameraList[readIndex])
            readIndex++
          } else {
            row.push({})
          }
        }
        rows.push(row)
      }
      return rows
    },
  },
  created() {
    this.local = MonitorLocal.current
    this.currentSize = this.local.size
    this.getProjectCamera()
  },
  methods: {
    getProjectCamera() {
      getProjectCamera()
        .then((res) => {
          const data = res.data || {}
          this.cameraList = data || []
          const list = []
          if (this.local.cameraList.length) {
            this.local.cameraList.forEach(camera => {
              const obj = this.cameraList.children.find(item => item.id == camera.id)
              console.log(obj);

              if (obj) {
                list.push(obj)
              }
            });
          }
          //当没有缓存数据时
          if (!list.length) {
            this.initCameraList()
          } else {
            this.saveCameraList(list)
          }
        })
        .catch((_) => { })
    },
    saveCameraList(val) {
      if (!val || !val.length) return
      this.selectCameraList = val
      this.local.cameraList = val
    },
    initCameraList() {
      const list = []
      const cameraList = this.cameraList.children || []
      for (let i = 0; i < cameraList.length; i++) {
        const camera = cameraList[i]
        if (list.length < this.currentSize * this.currentSize) {
          list.push(camera)
        } else {
          break
        }
      }
      this.saveCameraList(list)
    },
    changeSize(val) {
      this.currentSize = val
      this.local.size = val
      this.initCameraList()
    },
    towerCraneClick(val) {
      this.points = val
      this.visible = true
    },
  },
}
</script>

<style lang="less" scoped>
.jxyh-monitor {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &-tool {
    display: flex;
    flex-direction: row;
    align-items: center;

    &-size {
      width: 34px;
      height: 34px;
      cursor: pointer;
      margin-left: 20px;

      &+.monitor-select {
        margin-left: 72px;
      }
    }
  }

  &-sizes {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 16px;

    &-empty {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 580px;
    }
  }
}

.cell-monitor {
  width: 100%;
  height: 100%;

  ::v-deep .monitor-body {
    aspect-ratio: 16 / 7.5 !important;
  }
}

.el-row+.el-row {
  margin-top: 8px;
}
</style>
