import Vue from "vue"
class MonitorLocal {
  static LOCAL_KEY = "monitor_local"
  constructor() {
    const store = Vue.prototype.$store
    const projectId = store.getters.projectId
    this.localKey = MonitorLocal.LOCAL_KEY + projectId
    let config = localStorage.getItem(this.localKey) || "{}"
    this.config = JSON.parse(config)
  }
  _saveLocal() {
    localStorage.setItem(this.localKey, JSON.stringify(this.config))
  }
  set size(size) {
    this.config.size = size
    this._saveLocal()
  }
  get size() {
    return this.config.size || 2
  }
  get cameraList() {
    return this.config.cameraList || []
  }
  set cameraList(list = []) {
    this.config.cameraList = list
    this._saveLocal()
  }
  static get current() {
    const obj = new MonitorLocal()
    return new Proxy(obj, {})
  }
}
export default MonitorLocal