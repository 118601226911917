<template>
  <div class="monitor-select">
    <div
      class="monitor-select-title"
      @click="changeListStatus"
    >
      <span>监控列表</span>
      <i class="el-icon-arrow-down" />
    </div>

    <transition name="el-zoom-in-top">
      <div
        v-show="visible"
        class="monitor-select-list"
      >
        <div class="monitor-select-list-search">
          <el-input
            placeholder="请输入关键字进行过滤"
            v-model="searchKey"
            @change="searchKeyChange"
          >
          </el-input>
        </div>
        <div class="monitor-select-list-tree">
          <el-tree
            ref="tree"
            :data="options.id ? [options] : []"
            show-checkbox
            node-key="id"
            class="custom-tree"
            highlight-current
            :filter-node-method="filterNode"
          >
          </el-tree>
        </div>
        <div class="monitor-select-list-footer">
          <div @click="save">确定</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      searchKey: "",
      selectNodes: [],
    }
  },
  props: {
    //列表信息
    options: Object,
  },
  methods: {
    changeListStatus() {
      this.visible = !this.visible
    },
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    searchKeyChange(val) {
      this.$refs.tree.filter(val)
    },
    save() {
      const nodes = this.$refs.tree.getCheckedNodes()
      const selectNodes = []
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i]
        if (node.children) continue
        selectNodes.push(node)
      }
      this.$emit("save", selectNodes)
      this.visible = false
    },
  },
}
</script>

<style lang="less" scoped>
.monitor-select {
  &-title {
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 11px;
    font-size: 14px;
    color: #66bbf9;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 320px;
    cursor: pointer;
  }
  position: relative;
  &-list {
    position: absolute;
    left: 0;
    top: 46px;
    right: 0;
    height: 460px;
    background: #1e5087;
    box-shadow: 0px 2px 10px 0px rgba(0, 28, 87, 0.8);
    border: 1px solid;
    border-image: linear-gradient(135deg, rgba(24, 123, 205, 1), rgba(51, 216, 250, 1)) 1 1;
    z-index: 99;
    display: flex;
    flex-direction: column;
    &-search {
      padding: 16px;
    }
    &-tree {
      width: 100%;
      height: 100%;
      display: flex;
      overflow: auto;
      padding: 0 10px;
    }
    &-footer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 16px 16px 10px 16px;
      & > div {
        border-radius: 2px;
        border: 1px solid #66bbf9;
        font-size: 14px;
        color: #ffffff;
        line-height: 21px;
        padding: 11px 20px;
        cursor: pointer;
      }
    }
  }
}
::v-deep .el-tree-node__expand-icon {
  flex: unset !important;
}
/* 当鼠标悬停时改变背景颜色 */
::v-deep .el-tree-node__content {
  background: transparent;
  padding: 6px 15px;
}
::v-deep .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background: transparent;
}
::v-deep .el-tree-node__content:hover {
  background: #143a64;
}
.custom-tree {
  background: transparent;
  font-size: 14px;
  color: #fff;
  line-height: 21px;
  width: 100%;
}
::v-deep .el-input__inner {
  background: transparent;
  border-radius: 2px;
  border: 1px solid #66bbf9;
  color: #fff;
}
</style>
